a,
button,
input {
  box-shadow: none !important;
}

body,
html,
#root {
  height: 100%;
}
